import * as React     from "react";
import { Typography } from "@material-ui/core";
import firstIcon      from "../../images/firstIcon.svg";
import secondIcon     from "../../images/secondIcon.svg";
import thirdIcon      from "../../images/thirdIcon.svg";
import fourIcon       from "../../images/fourIcon.svg"
import { Theme }      from '@material-ui/core/styles';
import makeStyles     from '@material-ui/core/styles/makeStyles';
import WorkItem       from "../WorkItem";


const useStyles = makeStyles((theme: Theme) => ({
    circleItem: {
      height      : 150,
      width       : 150,
      border      : '3px solid black',
      borderRadius: '50%',
      position    : 'relative',

    },

    itemImage                   : {
      height  : 120,
      width   : 120,
      position: 'absolute',
      top     : 15,
      left    : 15
    },
    iconContainer               : {
      display: 'flex',
      width  : '100%'
    },
    workSection                 : {
      boxSizing    : 'border-box',
      padding      : '80px 116px',
      height       : 'auto',
      display      : 'flex',
      flexDirection: 'column',
    },
    sectionHeading              : {
      margin       : "60px 0px",
      fontSize     : 40,
      color        : 'rgb(25,28,31)',
      fontWeight   : 600,
      fontFamily   : `"Basier Circle", sans-serif`,
      letterSpacing: 0.4,
    },
    '@media (max-width: 1562px)': {
      sectionHeading     : {
        fontSize: 42}
      },
      '@media (max-width: 1398px)': {
      iconContainer      : {
        flexDirection: 'column',
        alignItems   : 'center',
        boxSizing    : 'border-box',
        padding      : '0px 64px'

      },
      workSection        : {
        padding: '80px 116px'
      },
      fullItem           : {
        width: '100%'
      },
      fullItemWithoutDots: {
        width: '100%'
      },

    },
    '@media (max-width: 1028px)': {
      iconContainer      : {
        flexDirection: 'column',
        alignItems   : 'center',

      },
      workSection        : {
        padding: '80px 0px'
      },
      fullItem           : {
        width: '100%'
      },
      fullItemWithoutDots: {
        width: '100%'
      }
    },
    '@media (max-width: 564px)' : {
      sectionHeading: {
        fontSize: 40
      }
    },
  }
))
const WorkSection: React.FC = (props: any) => {
  const classes = useStyles();
  const data = React.useMemo(() => [
    {
      icon   : firstIcon,
      heading: "App Design Consultation",
      text   : "Discuss your restaurants requirements \nwith our specialists"
    },
    {
      icon   : secondIcon,
      heading: "Branded App Development",
      text   : "Our expert team of developers" +
       "\nwill build your custom branded app \naccording to exact specifications"
    },
    {
      icon   : thirdIcon,
      heading: "Implementation",
      text   : "Customer relations will assist" +
        "\nwith a swift implementation and \ntutorial guide of the product"
    },
    {
      icon   : fourIcon,
      heading: "Start Taking Mobile Orders!",
      text   : "You are now in charge of your \nonline dining business!"
    }
  ], [])
  return (

    <Typography component="section" className={classes.workSection}>
      <Typography variant={"h2"} className={classes.sectionHeading}> How it works </Typography>
      <Typography component={"div"} className={classes.iconContainer}>
        {
          data.map((item: any, index: number) => (
            <WorkItem icon={item.icon} heading={item.heading} text={`${item.text}`} key={index * 0.55}/>
          ))
        }
      </Typography>
    </Typography>

  )
}
export default WorkSection;
