import * as React      from "react";
import { Button }      from "@material-ui/core";
import AppBar          from "@material-ui/core/AppBar";
import Toolbar         from "@material-ui/core/Toolbar";
import Typography      from "@material-ui/core/Typography";
import { Theme }       from '@material-ui/core/styles';
import makeStyles      from '@material-ui/core/styles/makeStyles';
import { Close, Menu } from "@material-ui/icons";
import IconButton      from "@material-ui/core/IconButton";
import ButtonGroup     from "@material-ui/core/ButtonGroup";


const useStyles = makeStyles((theme: Theme) => ({
  appBar                      : {
    backgroundImage   : 'linear-gradient(60deg, #64b3f4 -25%, #c2e59c 100%)',
    height            : "102px",
    boxShadow         : "none",
    backgroundPosition: 'bottom',
    zIndex            : 4000
  },
  link                        : {
    textDecoration: 'none',
    color         : theme.palette.primary.main
  },
  fill                        : {
    position       : 'absolute',
    top            : 0,
    left           : 0,
    display        : "block",
    backgroundColor: 'rgb(0, 0, 0,0.2)',
    height         : '100%',
    width          : '100%',
    zIndex         : 3
  },
  buttonGroup                 : {
    width   : '100%',
    fontSize: 32,
  },
  itemButton                  : {
    fontSize: 20
  },
  toolbar                     : {
    padding       : "22px 116px",
    display       : 'flex',
    justifyContent: "space-between",
    zIndex        : 5
  },
  logo                        : {
    fontSize: 38
  },
  buttonContainer             : {
    display: 'flex', justifyContent: 'space-between'
  },
  menu                        : {
    display: 'none'
  },
  navbar                      : {
    display : 'none',
    position: 'absolute',
    top     : -4,
    height  : '100vh',
    right   : 0,
    width   : '100%',

  },
  container                   : {
    position: 'relative'
  },
  '@media (max-width: 1024px)': {
    appBar: {
      backgroundImage: 'linear-gradient(60deg, #64b3f4 -60%, #c2e59c 100%)',
    }
  },

  '@media (max-width: 862px)': {
    buttonContainer: {
      display: 'none'
    },
    menu           : {
      display: 'block'
    },
    navbar         : {
      display        : 'flex',
      flexDirection  : 'column',
      alignItems     : 'flex-end',
      backgroundColor: 'white',
    },
    toolbar        : {
      padding: '22px 116px'
    }
  },
  '@media (max-width: 768px)': {
    toolbar: {
      padding: '22px 56px'
    },
    navbar : {
      padding: '40px 0'
    },
    appBar : {
      backgroundImage: 'linear-gradient(60deg, #64b3f4 -60%, #c2e59c 100%)',
    }
  },
  '@media (max-width: 564px)': {
    toolbar: {
      padding: '22px 28px'
    },
    logo   : {
      fontSize: 24
    }
  },
  '@media (max-width: 464px)': {
    appBar    : {
      backgroundImage: 'linear-gradient(60deg, #64b3f4 -90%, #c2e59c 100%)',
      height         : "64px",
    },
    toolbar   : {
      padding: '6px 28px'
    },
    itemButton: {
      fontSize: 14
    }
  },
}))
const Header = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnResize = () => {
    setIsOpen(false)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleOnResize);
  })
  return (
    <AppBar className={classes.appBar}>
      <Typography component="div" className={classes.fill}/>
      <Toolbar className={classes.toolbar}>
        <Typography component="div">
          <i className={`icon-cheqit_bw ${classes.logo}`}/>
        </Typography>
        <Typography component="div" className={classes.buttonContainer}>
          <a href={"#Solutions"} className={classes.link}>
            <Button color="secondary" style={{marginRight: 40}}>
              Solutions
            </Button>
          </a>
          <Button color="secondary">Resources</Button>
          <a href={"#Contact"} className={classes.link}>
            <Button color="secondary" variant={"outlined"} style={{marginLeft: 40}}>
              Get Started
            </Button>
          </a>
        </Typography>
        <IconButton className={classes.menu} onClick={e => setIsOpen(!isOpen)}>
          {isOpen ? <Close fontSize={"large"} color={"secondary"}/> :
            <Menu fontSize={"large"} color={"secondary"}/>}
        </IconButton>
      </Toolbar>
      <Typography component="div" className={classes.container}>
        <Typography component="div" className={classes.navbar} style={{display: isOpen ? 'flex' : 'none'}}>
          <ButtonGroup
            className={classes.buttonGroup}
            variant="text"
            orientation="vertical"
            color="primary"
            aria-label="vertical outlined primary button group"
          >
            <a href={'#Solutions'} className={classes.link}>
              <Button className={classes.itemButton} color="primary"
                      onClick={e => setIsOpen(false)}>
                Solutions
              </Button>
            </a>
            <Button className={classes.itemButton} color="primary"
                    onClick={e => setIsOpen(false)}>
              Resources
            </Button>
            <a href={"#Contact"} className={classes.link}>
              <Button className={classes.itemButton} color="primary"
                      onClick={e => setIsOpen(false)}>
                Get Started
              </Button>
            </a>
            <Button className={classes.itemButton}>Privacy and Policy</Button>
            <Button className={classes.itemButton}>Cookie Policy</Button>
          </ButtonGroup>
        </Typography>
      </Typography>
    </AppBar>
  )
}

export default Header
