import * as React      from 'react'
import { Typography }  from "@material-ui/core";
import SolutionItem    from "../SolutionItem";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import TrendingUpIcon  from '@material-ui/icons/TrendingUp';
import TouchAppIcon    from '@material-ui/icons/TouchApp';
import RedeemIcon      from '@material-ui/icons/Redeem';
import TimelineIcon    from '@material-ui/icons/Timeline';
import SettingsIcon    from '@material-ui/icons/Settings';
import makeStyles      from "@material-ui/core/styles/makeStyles";
import { Theme }       from "@material-ui/core/styles";
import clsx            from "clsx";


const useStyles = makeStyles((theme: Theme) => ({
    solutionSection             : {
      backgroundImage: 'linear-gradient(60deg, #64b3f4 -25%, #c2e59c 100%)',
      display        : 'flex',
      flexWrap       : 'wrap',
      alignItems     : 'center',
      justifyContent : 'center',
      padding        : "80px 164px",
      boxSizing      : 'border-box',
      height         : 'auto',
    },
    icon                        : {
      fontSize: 48
    },
    icon1                       : {
      color: '#92CAC6',
    },
    icon2                       : {
      color: '#A1D2D9',
    },
    icon3                       : {
      color: '#ADD9B0',
    },
    icon4                       : {
      color: '#8CC7CE',
    },
    icon5                       : {
      color: '#98CDC1',
    },
    icon6                       : {
      color: '#A5D5BC',
    },
    itemContainer               : {
      display       : 'flex',
      flexWrap      : 'wrap',
      justifyContent: 'space-between',
      width         : '70%',
      paddingTop    : '48px'
    },
    '@media (max-width: 1564px)': {
      solutionSection: {
        padding: '80px 28px'
      }
    },
    '@media (max-width: 912px)' : {
      itemContainer: {
        justifyContent: 'center'
      }
    },
    '@media (max-width: 564px)' : {
      icon: {
        fontSize: 32
      }
    }

  }
))
export default function Solutions() {
  const classes = useStyles();
  const data = React.useMemo(() => [{
    icon   : <PhoneIphoneIcon className={clsx(classes.icon, classes.icon1)}/>,
    heading: "Works on any device",
    text   : 'Android and iOS compatible'
  },
    {
      icon   : <TrendingUpIcon className={clsx(classes.icon, classes.icon2)}/>,
      heading: "Branded App",
      text   : 'Retain client data, save thousands of pounds on unwanted third-party commission fees and establish a stronger connection with your loyal customers'
    },
    {
      icon   : <TouchAppIcon className={clsx(classes.icon, classes.icon3)}/>,
      heading: "Admin Dashboard",
      text   : 'Cheqit’s all-in-one admin dashboard is integrated with different payment gateway systems, email, push notification, and SMS marketing systems'
    },
    {
      icon   : <RedeemIcon className={clsx(classes.icon, classes.icon4)}/>,
      heading: "Customer Loyalty",
      text   : 'Reducing waiting times, improving safety standards, and providing superior digital order & collect customer experiences to increase order volume, average check size, and loyalty '
    },
    {
      icon   : <TimelineIcon className={clsx(classes.icon, classes.icon5)}/>,
      heading: "Communication",
      text   : 'Using comprehensive CRM tools and push notifications to increase customer engagement and inclusion'
    },
    {
      icon   : <SettingsIcon className={clsx(classes.icon, classes.icon6)}/>,
      heading: "Analytics\n",
      text   : 'Feedback and analytics ensures sustainable growth. Use data-driven insights to track highest spenders, most profitable menu items, and overall sales, enabling menu optimization and sale maximization'
    },
  ], [classes.icon, classes.icon1, classes.icon2, classes.icon3, classes.icon4, classes.icon5, classes.icon6]);
  return (
    <Typography component="section" className={classes.solutionSection} id="Solutions">
      <Typography component="div" className={classes.itemContainer}>
        {data.map((item, index: number) => (
          <SolutionItem icon={item.icon} heading={item.heading} text={item.text}/>
        ))}
      </Typography>
    </Typography>
  )
}
