import * as React     from 'react';
import { Typography } from "@material-ui/core";
import Button         from "@material-ui/core/Button";
import { Theme }      from '@material-ui/core/styles';
import makeStyles     from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) => ({
  homeSection                 : {
    backgroundImage   : 'linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%)',
    height            : "100vh",
    width             : "100%",
    position          : 'relative',
    backgroundPosition: 'bottom',
    display           : 'flex',
    flexDirection     : 'column',
    justifyContent    : 'center',
    alignItems        : 'center',
    backgroundSize    : 'cover',
    backgroundRepeat  : "no-repeat",
  },
  inner                       : {
    position       : 'absolute',
    top            : 0,
    left           : 0,
    display        : "block",
    backgroundColor: 'rgb(0, 0, 0,0.2)',
    height         : '100%',
    width          : '100%',
    zIndex         : 3
  },
  outer                       : {
    zIndex        : 4,
    boxSizing     : 'border-box',
    padding       : 186,
    display       : 'flex',
    flexDirection : 'column',
    width         : '100%',
    justifyContent: 'center',
    alignItems    : 'center'
  },
  mainText                    : {
    color       : 'white',
    fontSize    : 55,
    fontWeight  : 900,
    marginBottom: 20,
    textAlign   : 'justify',
    fontFamily  : `"Basier Circle",sans-serif`
  },
  infoText                    : {
    color     : '#fefefe',
    fontWeight: 400,
    textAlign : 'justify',
    fontSize  : 21,
    fontFamily: `"Basier Circle",sans-serif`

  },
  roundedButton               : {
    marginTop      : 60,
    width          : 200,
    height         : 60,
    borderRadius   : 60,
    backgroundColor: 'white',
    animation      : `pulst 4000ms ease-in infinite`,
    justifyContent : 'center',
    alignItems     : 'center'
  },
  '@global'                   : {
    '*::-webkit-scrollbar'      : {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline        : '1px solid slategrey'
    },
    "@keyframes pulst"          : {
      "0%"  : {
        boxShadow: '0 0 0 0 rgba(171, 183, 183, 1),0 0 0 0 rgb(255, 255, 255,1)'
      },
      "40%" : {
        boxShadow: '0 0 0 0 rgba(174, 182, 183, 1),0 0 0 0 rgb(255, 255, 255,1)'
      },
      "80%" : {
        boxShadow: '0 0 0 30px rgb(128, 159, 255,0),0 0 0 20px rgb(128, 159, 255,0)'
      },
      "100%": {
        boxShadow: '0 0 0 0 rgb(128, 159, 255,0),0 0 0 20px rgb(128, 159, 255,0)'
      },
    },
  },
  gradientText                : {
    backgroundColor          : 'red',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundImage          : 'linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%)',
    backgroundSize           : '100%',
    backgroundRepeat         : 'repeat',
    backgroundClip           : 'text',
    textFillColor            : 'transparent',
    margin                   : 0,
    padding                  : 0,
    textAlign                : 'center',
  },
  '@media (max-width: 1564px)': {
    mainText: {
      fontSize: 50
    },
  },
  '@media (max-width: 1264px)': {
    outer : {
      padding : '0 124px',
      textAlign:'center'
    },
     mainText : {
      textAlign:'center'
     },
     infoText : {
      textAlign:'center',
       alignItems : 'center'
     }
},
  '@media (max-width: 786px)': {
    outer : {
      padding : '0 78px'
    }
  },
  '@media (max-width: 562px)' : {
    outer        : {
      padding: '0 28px'
    },
    mainText     : {
      fontSize : 32,
      textAlign: 'center'
    },
    infoText     : {
      fontSize: 16
    },
    gradientText : {
      fontSize : 'small',
      textAlign: 'center'
    },
    roundedButton: {
      height: 46,
      width : 155
    }
  }
}))


function HomeSection() {
  const classes = useStyles();
  return (
    <Typography component="section" id="Home" className={classes.homeSection}>
      <Typography component="div" className={classes.inner}/>
      <Typography component="div" className={classes.outer}>
        <Typography variant={"h3"} className={classes.mainText}>
          Online Ordering Solutions for Restaurants
        </Typography>
        <Typography component={'p'} className={classes.infoText}>
          Accelerating restaurant digitization, ensuring strong CRM capabilities and promoting greater digital sales
        </Typography>
        <a href={"/#Contact"} style={{textDecoration: 'none'}}>
          <Button variant={"contained"} color={"secondary"} className={classes.roundedButton}>
            <p className={classes.gradientText}>Get Started</p>
          </Button>
        </a>
      </Typography>
    </Typography>
  )
}

export default HomeSection;
