import * as React     from "react";
import { Typography } from "@material-ui/core";
import { Theme }      from '@material-ui/core/styles';
import makeStyles     from '@material-ui/core/styles/makeStyles';


interface ISolutionItemProps {
  icon: React.ReactNode;
  heading: string;
  text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    solutionItem               : {
      height         : 300,
      width          : 300,
      backgroundColor: 'white',
      borderRadius   : 12,
      display        : 'flex',
      flexDirection  : 'column',
      alignItems     : 'flex-start',
      padding        : 28,
      boxSizing      : 'border-box',
      marginBottom   : 40
    },
    itemHeading                : {
      fontSize : 24,
      textAlign: 'left',
      marginTop: 22,
      fontFamily :`"Basier Circle", sans-serif`,
      fontWeight:600
    },
    itemText                   : {
      margin   : 0,
      textAlign: 'left',
      marginTop: 6,
      fontSize:13,
      fontFamily :`"Basier Circle", sans-serif`,
      color :'rgb(139, 149, 158)',

    },
    '@media (max-width: 562px)': {
      itemHeading : {
        marginTop: 5,
        fontSize : 14
      },
      solutionItem: {
        height: 260,
        width : 260
      },
      itemText : {
        fontSize:12,
        marginTop:6
      }
    },
    '@media (max-width: 364px)': {
      itemHeading: {
        marginTop: 8,
        fontSize : 20
      },
    }
  }
))

export default function SolutionItem(props: ISolutionItemProps) {
  const {icon, heading, text} = props;
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.solutionItem}>
      {icon}
      <Typography variant={"h4"} className={classes.itemHeading}>
        {heading}
      </Typography>
      <Typography component={"p"} className={classes.itemText}>
        {text}
      </Typography>
    </Typography>
  )
}