import * as React     from "react";
import { Typography } from "@material-ui/core";
import { Theme }      from '@material-ui/core/styles';
import makeStyles     from '@material-ui/core/styles/makeStyles';
import TextField      from "@material-ui/core/TextField";
import Button         from "@material-ui/core/Button";
import Container      from "@material-ui/core/Container";


const useStyles = makeStyles((theme: Theme) => ({
  form      : {
    width        : '100%',
    marginTop    : theme.spacing(1),
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
  },
  container : {
    height : 'auto',
    padding: 40
  },
  heading   : {
    fontSize     : 40,
    color        : 'rgb(25,28,31)',
    fontWeight   : 600,
    fontFamily   : `"Basier Circle", sans-serif`,
    letterSpacing: 0.4,
  },
  background: {
    width          : '100%',
    backgroundColor: 'rgba(181,222,169,0.1)',
  },
  submit    : {
    margin: theme.spacing(3, 0, 2),
    width : 140,
  },
  input     : {
    width: '50%'
  },
  text      : {
    color     : 'rgb(139, 149, 158)',
    fontFamily: `"Basier Circle", sans-serif`
  },
  errorField : {
   color: "red",
    fontSize:12
  },
  started   : {
    textAlign: 'center',
    color    : 'white',
    margin   : 0,
    padding  : 0
  },
  '@media (max-width:1562px)': {
    heading: {
      fontSize: 44
    },
  },
  '@media (max-width:564px)' : {
    container: {
      padding: '40px 0px'
    },
    heading  : {
      fontSize: 40
    },
    text     : {
      fontSize: 14
    }
  }
}))
const ContactUs = () => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [phoneError, setPhoneError] = React.useState<string>("");
  const [nameError, setNameError] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [isError,setIsError] = React.useState<boolean>(true);

  const nameRegexp: any = RegExp(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/);
  const emailRegexp: any = RegExp(/^\w+@[a-zA-Z_]+?\.[a-z]{2,3}$/);
  React.useEffect(()=> {
  if((name && email && phone.length > 2) && (!nameError && !phoneError && !emailError)) {
    setIsError(false)
    }
  },[name, email,phone, nameError, phoneError, emailError,setIsError])
  const handleNameChange: (event: any) => void = (
    event: any
  ) => {
    const value = event.currentTarget.value;
    setName(value);
    if (value.length !== 0 && !nameRegexp.test(value)) {
      setNameError("enter valid name");
    } else {
      setNameError("")
    }
  }
  const handleEmailChange: (event: any) => void = (
    event: any
  ) => {
    const value = event.currentTarget.value;
    setEmail(value);
    if (!emailRegexp.test(value) && value.length !== 0) {
      setEmailError("enter valid email")

    } else {
      setEmailError("")

    }
  }

  const handlePhoneChange: (event: any) => void = (
      event: any
  ) => {
    const value = event.currentTarget.value;
    setPhone(value);
    if (value.length < 2) {
      setPhoneError("enter valid phone number")
      setIsError(true);
    } else {
      setPhoneError("")
    }
  }

const handleSendRequest = (event: any) =>{
    event.preventDefault();
    const url = "https://cheqit.app/subscribe";
    const myHeaders = new Headers();
    const formData: FormData = new FormData();

    myHeaders.append("Content-Type", "multipart/form-data");
    formData.append("email", email)
    formData.append("name", name)
    formData.append("phone",phone)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));}
  return (
    <Typography component="div" className={classes.background} id="Contact">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant={"h2"} className={classes.heading}>Get Started</Typography>
        <Typography component={"p"} className={classes.text}> Please fill out your details and we will be in
          touch!</Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="Name"
            label="Name"
            type="Name"
            id="Name"
            color="primary"
            autoComplete="current-name"
            className={classes.input}
            value={name}
            onChange={handleNameChange}
          />
          {nameError ? <div className = {classes.errorField}>{nameError}</div> : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            className={classes.input}
            color="primary"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError ? <div className = {classes.errorField}>{emailError}</div> : null}
          <TextField type = "number"
            variant="outlined"
            margin="normal"
            required
            name="Number"
            label="Number"
            id="Number"
            autoComplete="current-number"
            color="primary"
            className={classes.input}
            onChange={handlePhoneChange}

          />
          {phoneError ? <div className = {classes.errorField}>{phoneError}</div> : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            color="primary"
            onClick={handleSendRequest}
            disabled={isError}
          >
            <p className = {classes.started}>Get Started</p>
          </Button>
        </form>
      </Container>
    </Typography>
  )
}
export default ContactUs