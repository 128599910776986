import * as React     from "react";
import { Typography } from "@material-ui/core";
import { Theme }      from '@material-ui/core/styles';
import makeStyles     from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) => ({
  circleItem                  : {
    height      : 150,
    width       : 150,
    border      : '3px solid black',
    borderRadius: '50%',
    position    : 'relative',
  },
  itemImage                   : {
    height  : 120,
    width   : 120,
    position: 'absolute',
    top     : 15,
    left    : 15
  },
  iconContainer               : {
    display: 'flex',
  },
  fullItem                    : {
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
    width        : '25%',
    position     : 'relative',
  },
  fullItemWithoutDots         : {
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
    width        : '25%',
    position     : 'relative',
  },
  texts                       : {
    textAlign: 'center'
  },
  heading                     : {
    fontSize  : 18,
    fontWeight: 'bold',
    margin    : "8px 0"
  },
  '@media (max-width: 1564px)': {
    fullItem: {
      width        : '100%',
      display      : 'flex',
      flexDirection: 'row',
      marginBottom : 60,
    },
    texts   : {
      paddingLeft  : 60,
      display      : 'flex',
      flexDirection: 'column',
      alignItems   : 'flex-start',
      textAlign    : 'start'
    }
  },
  '@media (max-width: 986px)' : {
    fullItem           : {
      width        : '100%',
      marginBottom : 20,
      flexDirection: 'column'
    },
    fullItemWithoutDots: {
      width       : '100%',
      marginBottom: 20
    },
    texts              : {
      alignItems: 'center',
      padding   : 0,
      textAlign : 'center'
    }
  },
  '@media (max-width:768px)'  : {
    iconContainer: {
      display      : 'flex',
      flexDirection: 'column'
    },

    fullItem           : {
      width       : '100%',
      marginBottom: 30,

    },
    fullItemWithoutDots: {
      width       : '100%',
      marginBottom: 30
    }
  }
}))

interface IWorkItemProps {
  icon: any;
  heading: string;
  text: string;
}

const WorkItem = (props: IWorkItemProps) => {
  const classes = useStyles();
  const {icon, heading, text} = props;
  return (
    <Typography component={"div"} className={classes.fullItem}>
      <Typography component="div" className={classes.circleItem}>
        <img src={icon} className={classes.itemImage} alt={"ok"}/>
      </Typography>
      <Typography component="div" className={classes.texts}>
        <Typography component="div" className={classes.heading}>
          {heading}
        </Typography>
        <Typography component="p">
          {text}
        </Typography>
      </Typography>
    </Typography>

  )
};
export default WorkItem;
