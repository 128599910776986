import * as React from 'react';
import Typography from "@material-ui/core/Typography";

const Terms = () => {
    const TermsData = React.useMemo(() => {
        return [
            {
                title: "About Us",
                texts: ["The branded applications powered by Cheqit LTD and the website Cheqit.app is operated by Cheqit LTD (“Us”, “we” or “Cheqit”) incorporated and registered in England and Wales, located at 71-75 Shelton Street, Covent Garden, London, WC2H9JQ. Cheqit’s company registration number is 12859894", "Cheqit is a B2B SaaS business that provides online ordering solutions and branded whitelabel mobile applications for restaurants. You agree that by accessing the website and/or Branded App, you have read, understood, and agree to be bound by all of these Terms and Conditions."]
            },
            {
                title: "Purpose",
                texts: ["The purpose of our Service is to provide a simple and convenient service to you, linking you to our partner restaurant and allowing you to download our partner branded applications for mobile ordering and payment. The Service will be provided to you by our partner restaurants and may be used both for dining-in and for takeaway orders"]
            },
            {
                title: "Service Availability",
                texts: ["Cheqit LTD offers the users of its partner restaurant’s branded application a mobile ordering service, directed to people residing in the United Kingdom. When using Cheqit powered branded applications you will be able to select our partner restaurants nearest branch via an interactive map. This will ensure that you place your online order at the right place. You will be able to browse through menu items, create customizations based on the respective menu items, save these customisations to your favourites and place orders. All information regarding menu item stock availability and the menu itself will be updated regularly by our partner restaurant. Once you have placed an order, you will receive order updates from our partner restaurants in the form of push notifications. Operating hours will be presented on the branded application and it will vary for each of our partner restaurants.", "The information provided on Cheqit’s website or branded application is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country."]
            },
            {
                title: "Order Payment and Collection",
                texts: ["When you place an order, your account will automatically be charged to the payment details you have provided upon creating your account in the application. Once you receive confirmation from our Partner restaurant that your order has been accepted and is ready for pickup, it is your responsibility to collect your order at your chosen collection time and location."]
            },
            {
                title: "Information you Provide Us",
                texts: ["5.1 You represent and warrant that: (a) all registration information you submit will be true, accurate, current, and complete and relate to you and not a third party; (b) you will maintain the accuracy of such information and promptly update such information as necessary; (c) you will keep your password confidential and will be responsible for all use of your password and account; (d) you have the legal capacity and you agree to comply with these Terms and Conditions.", "If you know or suspect that anyone other than you knows your user information (such as an identification code or user name) and/or password you must promptly notify us at haiksimonian@cheqit.app.", "5.2 If you provide any information that is untrue, inaccurate, not current or incomplete, we may suspend or terminate your account."]
            },
            {
                title : "Our Content",
                texts : ["6.1 Unless otherwise indicated, our Service including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the website and application are owned or licensed to us, and are protected by copyright and trademark laws.", "6.2 Except as expressly provided in these Terms and Conditions, no part of the website, application, Services or Our Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.", "6.3 You shall not (a) try to gain unauthorised access to our Service or any networks, servers or computer systems connected to our Service; and/or (b) make for any purpose including error correction, any modifications, adaptations, additions or enhancements to the website, application or Our Content, including the modification of the paper or digital copies you may have downloaded.", "6.4 Although we make reasonable efforts to update the information on our website and application, we make no representations, warranties or guarantees, whether express or implied, that Our Content on our Service is accurate, complete or up to date."]
            },
            {
                title : "Website and Application Management",
                texts : ["7.1 We reserve the right at our sole discretion, to (1) monitor our Service for breaches of these Terms and Conditions; (2) take appropriate legal action against anyone in breach of applicable laws or these Terms and Conditions; (3) refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any of your Contributions; (4) remove from our Service or otherwise disable all files and content that are excessive in size or are in any way a burden to our systems; and (5) otherwise manage our Service in a manner designed to protect our rights and property and to facilitate the proper functioning of our Service.", "7.2 We do not guarantee that our Service will be secure or free from bugs or viruses.", "7.3 You are responsible for configuring your information technology, computer programs and platform to access our Service and you should use your own virus protection software."],
            },
            {
                title : "Modifications to and Availability",
                texts : ["8.1 We reserve the right to change, modify, or remove the contents of our Service at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.", "8.2 We cannot guarantee that our Service will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the website and application, resulting in interruptions, delays, or errors. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use our Service during any downtime or discontinuance of our Services. We are not obliged to maintain and support our Service or to supply any corrections, updates, or releases.", "8.3 There may be information on the website and application that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information at any time, without prior notice."]
            },
            {
                title : "Disclaimer/Limitation of Liability",
                texts : ["9.1 To the extent permitted by law, Cheqit provides our Service and content on an as-is and as-available basis. You agree that your use of our Service will be at your sole risk. We make no warranties or representations, express or implied, regarding the accuracy or availability of our Service, or that it will be timely, error-free or that defects will be corrected. We will not be responsible for any delay or failure to comply with our obligations under these Terms and Conditions if such delay or failure is caused by an event beyond our reasonable control. Neither Cheqit nor any partners shall have any liability to you for any direct, indirect, special or consequential losses or damages arising in contract, or otherwise arising from your use of or your inability to use our Service.", "9.2 In the event that Cheqit or the partner is found to be liable to you our total aggregate liability is limited to the fee of £1. This does not include or limit in any way Cheqit’s or any partners liability for any matter for which it would be illegal for us or it to exclude, or attempt to exclude, our or its liability, including liability for death or personal injury caused by negligence or for fraud or fraudulent misrepresentation."]
            },
            {
                title : "Term and Termination",
                texts : ["10.1 These Terms and Conditions shall remain in full force and effect while you use our Service. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at haiksimonian@cheqit.app.", "10.2 Without limiting any other provision of these Terms and Conditions, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of our Service (including blocking certain IP addresses), to any person for any reason including without limitation for breach of any representation, warranty or covenant contained in these Terms and Conditions or of any applicable law or regulation.", "If we determine, in our sole discretion, that your use of our Service is in breach of these Terms and Conditions or of any applicable law or regulation, we may terminate your use or participation of our Service or delete your profile and any content or information that you posted at any time, without warning, in our sole discretion.", "10.3 If we terminate or suspend your account for any reason set out in this Section, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress."]
            }
        ]
    }, [])
    return (
        <div>
            <Typography variant="h1">
                Cheqit LTD Terms and Conditions of Service
            </Typography>
            <p>
                Welcome to Cheqit.app’s website and application. This page will tell you the terms and conditions on
                which both we and our partner restaurants provide mobile ordering software for you. By accessing our
                website and using our mobile ordering software you agree to be bound by these terms and conditions and
                our privacy policy. Your personal data is handled by Cheqit LTD in accordance with our privacy policy.
                If you have any questions relating to these terms and conditions please contact us at
                haiksimonian@cheqit.app. If you do not accept these terms and conditions in full then you are prohibited
                from using the website and application and you must discontinue use of our service.
            </p>
            <Typography component="div">
                <ol>
                    {TermsData.map((item: { title: string, texts: string[] }, index: number) => (
                            <li key={Math.random() * index}><b>{item.title}</b> {item.texts.map((text) => (
                                <p>{text}</p>
                            ))}</li>
                        )
                    )}
                </ol>
            </Typography>
        </div>
    )
}
export default Terms;
