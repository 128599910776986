import * as React            from 'react'
import makeStyles            from "@material-ui/core/styles/makeStyles";
import { Theme }             from "@material-ui/core/styles";
import { Typography }        from "@material-ui/core";
// import logo                  from "../../images/logo.svg"
import IconButton            from "@material-ui/core/IconButton";
import { LinkedIn, Twitter } from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) => ({
    form                       : {
      width        : '100%', // Fix IE 11 issue.
      marginTop    : theme.spacing(1),
      display      : 'flex',
      flexDirection: 'column',
      alignItems   : 'center'
    },
    logo                       : {
      fontSize:24
    },
    container                  : {
      height         : 'auto',
      backgroundImage: 'linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%)',
      boxSizing      : 'border-box',
      padding        : '28px 166px'
    },
    submit                     : {
      margin: theme.spacing(3, 0, 2),
      width : 140,
    },
    input                      : {
      width: '50%'
    },
    allSections                : {
      display       : 'flex',
      flexDirection : 'row',
      justifyContent: 'space-around'
    },
    link                       : {
      textDecoration:'none'
    },
    customSection              : {
      display      : 'flex',
      flexDirection: 'column',
      alignItems   : 'baseline'
    },
    bottomFooter               : {
      backgroundColor: 'rgba(181,222,169,0.1)',
      padding        : '28px 116px',
      display        : 'flex',
      flexDirection  : 'row',
      alignItems     : 'center',
      justifyContent : 'space-between'
    },
    iconContainer              : {},
    mainItem                   : {
      fontSize  : 20,
      fontWeight: 600,
      color     : 'azure'
    },
    customItem                 : {
      color       : 'darkolivegreen',
      marginBottom: '6px'
    },
    '@media (max-width: 862px)': {
      container   : {
        padding: '28px 28px',
      },
      bottomFooter: {
        padding: '28px 72px',
      }

    },
    '@media (max-width: 564px)': {
      mainItem   : {
        fontSize: 16
      },
      customItem : {
        fontSize: 16
      },
      allSections: {
        alignItems: 'center',
        flexWrap  : 'wrap'
      },
      bottomFooter : {
        padding :'28px 16px'
      }
    },
    '@media (max-width: 364px)': {
      customItem: {
        fontSize: 14
      },
      mainItem  : {
        fontSize: 14
      }
    }
  }
));

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Typography component="div" className={classes.container}>
        <Typography component="div" className={classes.allSections}>
          <Typography component="div" className={classes.customSection}>
            < i className = {`icon-cheqit_bw ${classes.logo}`}/>
            <Typography component="div" className={classes.iconContainer}>
              <IconButton aria-label="linkedin">
                <LinkedIn/>
              </IconButton>
              <IconButton aria-label="twitter">
                <Twitter/>
              </IconButton>
            </Typography>
          </Typography>
          <Typography component="div" className={classes.customSection}>
            <Typography component="div" className={classes.mainItem}>
              PRODUCT
            </Typography>
            <a href = {"#Branded_Apps"} className = {classes.link}>
            <Typography component="div" className={classes.customItem}>
              Branded Apps
            </Typography>
            </a>
            <Typography component="div" className={classes.customItem}>
              CRM
            </Typography>
          </Typography>
          <Typography component="div" className={classes.customSection}>
            <Typography component="div" className={classes.mainItem}>
              COMPANY
            </Typography>
            <Typography component="div" className={classes.customItem}>
              About Us
            </Typography>
            <a href = {"#Resources"} className = {classes.link}>
            <Typography component="div" className={classes.customItem}>
              Resources
            </Typography>
            </a>
          </Typography>
          <Typography component="div" className={classes.customSection}>
            <Typography component="div" className={classes.mainItem}>
              LEGAL
            </Typography>
            <Typography component="div" className={classes.customItem}>
              Privacy Policy
            </Typography>
            <Typography component="div" className={classes.customItem}>
              Cookie Policy
            </Typography>
            <Typography component="div" className={classes.customItem}>
              GDPR
            </Typography>
          </Typography>
        </Typography>
      </Typography>
      <Typography component="div" className={classes.bottomFooter}>
        <Typography style={{textAlign: 'start'}}>  &copy;Cheqit. {new Date().getFullYear()} All right
          reserved </Typography>
      </Typography>
    </>

  )
}
export default Footer
