import * as React from "react";
import "./App.css";
import {Route, Switch,} from "react-router-dom";
import MainApp from "./MainApp";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";

function App() {
    return (
        <Switch>
            <Route path="/" exact component={MainApp}/>
            <Route path="/privacy" component={Privacy}/>
            <Route path = "/terms" component = {Terms}/>
        </Switch>
    );
}

export default App;
