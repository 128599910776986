import React                from 'react';
import Slider               from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import item1                from "../../images/item1.png";
import item2                from "../../images/item2.png";
import item3                from "../../images/item3.png";
import item4                from "../../images/item4.png";
import item5                from "../../images/item5.png"
import { Theme }            from '@material-ui/core/styles';
import makeStyles           from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    width: 266,
    left:8
  },
  '@media (max-width: 562px)': {
    slider : {
    width:212
    } }

}));

function CustomSlider() {
  const settings = {
    width         : 300,
    autoplay      : true,
    autoplaySpeed : 100,
    infinite      : true,
    arrows        : false,
    speed         : 2000,
    slidesToShow  : 1,
    slidesToScroll: 1,
  };
  const classes = useStyles();
  return (
    <Slider {...settings} className={classes.slider}>
      <img src={item1} alt="section1"/>
      <img src={item2} alt="section2"/>
      <img src={item3} alt="section3"/>
      <img src={item4} alt="section4"/>
      <img src={item5} alt="section5"/>
    </Slider>
  );

}
export default  CustomSlider
