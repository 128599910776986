import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { darken }       from '@material-ui/core/styles/colorManipulator';
import { lighten }      from '@material-ui/core/styles/colorManipulator';

export const themeOptions: ThemeOptions = {
  palette  : {
    type     : 'light',
    primary  : {
      main : 'rgb(181,222,162)',
      dark : darken('rgb(181,222,162)', .2),
      light: lighten('#64b3f4', .2),
    },
    secondary: {
      main : '#fff',
      dark : darken('#fff', .1),
      light: lighten('#fff', .2),
    },
  },
};
