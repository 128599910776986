import * as React          from 'react';
import { Typography }      from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import macbook             from "../../images/macbook.png"
import { Theme }           from '@material-ui/core/styles';
import makeStyles          from '@material-ui/core/styles/makeStyles';
import gff              from "../../images/gff.svg";
// import {useParams} from "react-router-dom"

const useStyles = makeStyles((theme: Theme) => ({
  infoSection   : {
    display      : 'flex',
    flexDirection: 'column',
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat'
  },
  sliderPart    : {
    display      : 'flex',
    flexDirection: 'row'
  },
  contentPart   : {
    display       : 'flex',
    alignItems    : 'center',
    width         : '100%',
    justifyContent: 'space-around',
    padding: '60px 116px',
    boxSizing: 'border-box',
    flexDirection:'row-reverse',
  },
  frameImage    : {
    height  : 704,
    position: 'absolute',
    zIndex  : 10,
    width   : 355,
    left    : -25,
    top     : -25,
  },
  iconLi : {},
  heading : {

  },
  listUl        : {
    listStyle    : 'none',
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'baseline',
    textAlign:'start'
  },
  listLi        : {
    fontSize      : 20,
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'baseline'
  },
  liText        : {
    marginTop: 24
  },
  macbookFrame  : {
    position: 'absolute',
    zIndex  : 10,
    height  : 474,
    width   : 828,
    left    : -25,
    top     : -215,
  },
  innerDashboard: {
    position: 'relative',
    height  : 368,
    top     : 15,
    right   : 8
  },
   textPart : {
    width:'60%'
   },
  dashboardPart : {
    width:'60%',

  },
  infoSectionPart : {
    height:'auto',
    display:'flex',
    marginBottom: 120,
    backgroundImage   : `url(${gff})`,
    backgroundPositionX : 1248,
    backgroundRepeat : 'repeat-y'
  },
  '@media (max-width: 1564px)': {
    contentPart : {
      padding: '60px 66px',
    },
    dashboardPart : {
      width:'60%'
    },
    textPart : {
      width:'40%'
    },
    heading        : {
      fontSize: 44
    },
    liText         : {
      fontSize: 18
    },
    iconLi : {
      fontSize:18
    }
  },
  '@media (max-width: 1366px)': {
    dashboardPart : {
      width:'70%'
    },
    textPart : {
      width:'30%'
    }
  },
  '@media (max-width: 1244px)': {
    contentPart : {
      flexDirection : 'column-reverse'
    },
    textPart : {
      width:'100%'
    },
    dashboardPart : {
      width:'100%',
      position:'unset'
    },
    macbookFrame : {
      position: 'unset'
    },
    '@media (max-width: 912px)': {
      macbookFrame : {
        width:700,
        height:400
      },
      infoSection : {
        backgroundImage : 'none'
      }
    },
    '@media (max-width: 774px)': {
      macbookFrame : {
        width :420,
        height:270
      }
    },
    '@media (max-width: 562px)': {
      dashboardPart : {
        display: 'none'
      },
      contentPart : {
        padding : '60 28px'
      },
      liText : {
        fontSize :18
      },
      heading : {
        fontSize:40
      },
      iconLi : {
        fontSize:14
      }
    }
  }


}))
const InfoSection: React.FC = () => {
  const classes = useStyles();
  // let id = useParams();
  return (
      <Typography component="div" className = {classes.infoSectionPart} id = "info">
        <Typography component="div" className={classes.contentPart} style = {{}}>
          <Typography component="div" style={{position: 'relative'}} className = {classes.dashboardPart}>
            <img src={macbook} className={classes.macbookFrame} alt={"macbook"}/>
          </Typography>
          <Typography component="div" className={classes.textPart}>
            <Typography variant={"h2"} className={classes.heading}>
              Online Order Management
            </Typography>
            <ul className={classes.listUl}>
              <li className={classes.listLi}><ArrowForwardIos className = {classes.iconLi}/>
                <p className={classes.liText}>Manage, review and process your online orders via the bespoke Cheqit
                  dashboard</p>
              </li>
              <li className={classes.listLi}><ArrowForwardIos className = {classes.iconLi}/>
                <p className={classes.liText}>Update menu items, post specials and analyse customer ordering
                  activities </p>
              </li>
            </ul>
          </Typography>
        </Typography>
      </Typography>

  )
}
export default InfoSection;
