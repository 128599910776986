import * as React from 'react';
import Header from "./Components/Header";
import HomeSection from "./Components/HomeSection";
import PhoneSection from "./Components/PhoneSection";
import InfoSection from "./Components/InfoSection";
import Solutions from "./Components/Solutions";
import WorkSection from "./Components/WorkSection";
import ContactUs from "./Components/ContactUs";
import Footer from "./Components/Footer";


const MainApp = () => {
    return (
        <div className="App">
            <Header/>
            <HomeSection/>
            <PhoneSection/>
            <InfoSection/>
            <Solutions/>
            <WorkSection/>
            <ContactUs/>
            <Footer/>
        </div>
    )
}
export default MainApp;