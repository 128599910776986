import * as React          from 'react';
import { Typography }      from "@material-ui/core";
import frame               from "../../images/frame.png"
import { ArrowForwardIos } from "@material-ui/icons";
import { Theme }           from '@material-ui/core/styles';
import makeStyles          from '@material-ui/core/styles/makeStyles';
import phone                 from "../../images/phone.svg";
import CustomSlider        from "../CustomSlider";


const useStyles = makeStyles((theme: Theme) => ({
  infoSection                 : {
    display         : 'flex',
    flexDirection   : 'column',
    backgroundImage : `url(${phone})`,
    backgroundRepeat: 'no-repeat',
    height          : 'auto',
  },
  sliderPart                  : {
    display      : 'flex',
    flexDirection: 'row'
  },
  contentPart                 : {
    display       : 'flex',
    flexDirection : 'row',
    alignItems    : 'center',
    width         : '100%',
    justifyContent: 'space-around',
    padding       : '120px 116px',
    boxSizing     : 'border-box'
  },
  frameImage                  : {
    height  : 629,
    position: 'absolute',
    zIndex  : 10,
    width   : 315,
    left    : -15,
    top     : -25,
  },
  heading : {
  },
  listUl                      : {
    listStyle    : 'none',
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'baseline',
    textAlign    : 'start'
  },
  listLi                      : {
    fontSize      : 20,
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'baseline'
  },
  liText                      : {
    marginTop: 24
  },
  macbookFrame                : {
    position: 'absolute',
    zIndex  : 10,
    height  : 474,
    width   : 680,
    left    : -25,
    top     : -25,
  },
  innerDashboard              : {
    position: 'relative',
    height  : 368,
    top     : 15,
    right   : 8
  },
  phoneInitial                : {
    position : 'relative',
  },
  textPart                    : {
    width: '60%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  dashboardPart               : {
    width: '40%',

  },
  iconLi                      : {},
  phoneController             : {},
  '@media (max-width: 1564px)': {
    contentPart: {
      padding: '60px 66px',
    },
    liText     : {
      fontSize: 18
    },
    heading : {
      fontSize:44
    },
    iconLi : {
      fontSize:18
    }
  },
  '@media (max-width: 912px)' : {
    contentPart: {
      flexDirection: 'column-reverse'
    },
    textPart   : {
      width    : '100%',
      marginTop: '60px'
    },
    infoSection : {
      backgroundImage : 'none'
    }

  },
  '@media (max-width: 562px)' : {
    phoneController: {
      width: 'unset'
    },
    phoneInitial   : {
      width    : 'unset',
      marginTop: 40
    },
    frameImage     : {
      height  : 500,
      position: 'absolute',
      zIndex  : 10,
      width   : 252,
      left    : -14,
      top     : -20,
    },
    heading        : {
      fontSize: 40
    },
    liText         : {
      fontSize: 18
    },

    contentPart: {
      padding: '0 28px'
    },
    iconLi     : {
      fontSize: 14
    }
  }
}))

const PhoneSection: React.FC = () => {
  const classes = useStyles();
  return (
    <Typography component="section" id="Branded_Apps" className={classes.infoSection}>
      <Typography component="div" className={classes.sliderPart}>
        <Typography component="div" className={classes.contentPart}>
          <Typography component="div" className={classes.phoneController}>
            <Typography component="div" className={classes.phoneInitial}>
              <img src={frame} className={classes.frameImage} alt={"ok"}/>
              <CustomSlider/>
            </Typography>
          </Typography>
          <Typography component="div" className={classes.textPart}>
            <Typography variant={"h2"} className={classes.heading}>
              Custom Branded Apps
            </Typography>
            <ul className={classes.listUl}>
              <li className={classes.listLi}>
                <ArrowForwardIos className = {classes.iconLi}/>
                <p className={classes.liText}>
                  Promote and develop your brand with your very own custom
                  mobile app</p>
              </li>
              <li style={{textAlign: "start"}} className={classes.listLi}>
                <ArrowForwardIos className = {classes.iconLi}/>
                <p className={classes.liText}>Designed and developed by our skilful team for you to
                  manage online orders,<br/> payments and delivery</p>
              </li>
            </ul>
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  )
}
export default PhoneSection
